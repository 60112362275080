@font-face {
  font-family: 'PlayfairDisplay';
  src: url('./assets/font/PlayfairDisplay-VariableFont_wght.ttf') format('truetype')
}
@font-face {
  font-family: 'ChonburiRegular';
  src: url('./assets/font/Chonburi-Regular.ttf') format('truetype')
}
.App {
  text-align: center;
  font-family: 'PlayfairDisplay','ChonburiRegular';
  background: '#ffe8df'; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
